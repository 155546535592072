import { useAuthContext } from 'Context/AuthContext';
import { useCompanyContext } from 'Context/CompanyContext';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

export const useLogout = () => {
  const [_cookies, _setCookie, removeCookie] = useCookies(['token']);
  const { dispatch } = useAuthContext();
  const { dispatch: companyDispatch } = useCompanyContext();
  const { company } = useCompanyContext();
  const navigate = useNavigate();
  const logout = () => {
    removeCookie('token', {
      path: '/',
      domain: process.env.REACT_APP_DOMAIN,
    })
    dispatch({ type: 'LOGOUT' });
    if (process.env.REACT_APP_HOST === window.location.hostname) {
      companyDispatch({ type: 'REMOVE_COMPANY', payload: null });
    } else if (!company) {
      window.location.host = process.env.REACT_APP_HOST;
    } else {
      navigate('/');
    }
  };
  return { logout };
};
